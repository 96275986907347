	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/09/2020 20:26
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("../../icons/Flaticon.eot"),
    /* src: url("../../icons/?#iefix") format("embedded-opentype"), */
    url("../../icons/Flaticon.woff2") format("woff2"),
    url("../../icons/Flaticon.woff") format("woff"),
    url("../../icons/Flaticon.ttf") format("truetype"),
    url("../../icons/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
      font-family: "Flaticon";
      src: url("../../icons/Flaticon.svg#Flaticon") format("svg");
      font-display: swap;
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-auction:before { content: "\f100"; }
.flaticon-right-arrow:before { content: "\f101"; }
.flaticon-left-arrow:before { content: "\f102"; }
.flaticon-right-arrow-1:before { content: "\f103"; }
.flaticon-play:before { content: "\f104"; }
.flaticon-money:before { content: "\f105"; }
.flaticon-shopping-basket:before { content: "\f106"; }
.flaticon-user:before { content: "\f107"; }
.flaticon-bag:before { content: "\f108"; }
.flaticon-time:before { content: "\f109"; }
.flaticon-pencil:before { content: "\f10a"; }
.flaticon-settings:before { content: "\f10b"; }
.flaticon-alarm:before { content: "\f10c"; }
.flaticon-star:before { content: "\f10d"; }
.flaticon-shake-hand:before { content: "\f10e"; }
.flaticon-best-seller:before { content: "\f10f"; }
.flaticon-trophy:before { content: "\f110"; }
.flaticon-check:before { content: "\f111"; }
.flaticon-edit:before { content: "\f112"; }
.flaticon-check-1:before { content: "\f113"; }
.flaticon-dashboard:before { content: "\f114"; }
.flaticon-buffer:before { content: "\f837"; }