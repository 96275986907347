/* Font for FURS */
@font-face {
    font-family: "RepublikaRegular";
    font-style: normal;
    font-weight: normal;
    src: url(../../fonts/republika-regular-webfont.woff2) format("woff2");
    font-display: swap;
}

a,
input,
.table,
.dropdown-item,
.custom-select,
.login-form .form-group label,
.swal2-content,
.swal2-content {
    color: var(--bodyText);
}

.swal2-title {
    color: var(--bodyText) !important;
}

.btn:hover {
    color: var(--bodyText);
}

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
}

.btn:disabled:hover {
    color: #fff;
}

.swal2-styled.swal2-confirm {
    color: var(--white) !important;
}

.swal2-styled.swal2-cancel {
    background-color: var(--darkBG);
    border: 1px solid var(--dark);
    color: var(--white);
}

.dropdown-menu,
.form-control,
.account-wrapper,
.login-form .form-group input,
.swal2-select {
    background-color: var(--elementBG);
}

.dropdown-item:focus, .dropdown-item:hover {
    background-color: var(--mainBGColor);
    color: var(--bodyText);
}

.displayNone {
    display: none;
}

.filterItemsList li {
    padding: 0;
}

.btn-primary {
    background-color: var(--mainBGColor);
    background: var(--mainBGColor);
    border-color: var(--mainColor);
    color: var(--white);
}

.text-primary {
    color: var(--mainColor) !important;
}

.page-item.active .page-link {
    background-color: var(--mainBGColor);
    background: var(--mainBGColor);
    border-color: var(--mainColor);
    color: var(--white);
}

.loading-screen {
    background-color: var(--mainBGColor);
}

.login-btn {
    border-color: var(--headerTextColor);
    background: transparent;
    color: var(--headerTextColor);
    text-transform: uppercase;
}

.banner-section p,
.banner-section h2 {
    color: var(--headerTextColor)
}

.ending-picture {
    cursor: pointer;
    border: 1px solid #ddd;
    width: 100%;
    min-height: 146px;
    background-color: #f6f6ff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.browse-item-2:hover {
    background-color: var(--mainBGColor);
}

.browse-item-2:hover > .content > span {
    color: var(--light);
}

.toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle-btn > label {
    transform: scale(1.3);
}

.bid-info-red {
    color: red;
}

.bid-info-green {
    color: rgb(42, 157, 42);
}

.banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control:disabled, .form-control[readonly] {
    opacity: 0.7 !important;
}

#world > canvas {
    margin-bottom: -160px;
}

.header-top-wrapper {
    min-height: 48px;
}

.header-top-wrapper .logo {
    width: 250px;
    position: relative;
}

.header-top-wrapper .logo a {
    max-height: 51px;
}

.header-top-wrapper .logo .second-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0px;
}

.header-top-wrapper .logo img {
    height: 40px;
    width: auto;
    max-width: 100%;
    margin-right: 10px;
}

.header-top-wrapper .logo .second-logo-text {
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: normal;
    display: inline-block;
    line-height: 120%;
    width: 120px;
}
.header-top-wrapper .logo .second-logo-second-text {
    font-size: 1.2rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: normal;
    display: inline-block;
    line-height: 120%;
    white-space: nowrap;
    margin-left: 10px;
}

body .card-title {
    font-size: 1.3rem;
}

body .login .card-title {
    font-size: 1.5rem !important;
}

.button-search {
    background-color: #1e77bc;
    color: #fff;
}

.button-link {
    padding: 10px 15px !important;
    color: var(--headerTextColor);
    text-transform: uppercase;
    font-weight: 500;
    font-family: inherit;
    border: none;
    background: none;
}

.button-link i {
    margin-right: 4px;
}

.account-wrapper .right-side {
    background: -webkit-linear-gradient(90deg, var(--mainColorDark) 0%, var(--mainColorLight) 100%) !important;
}

.cl-white * {
    color: var(--white);
}

.login-with li button {
    color: #171d1c;
    border-radius: 10px;
    border: 1px solid rgba(97, 90, 191, 0.2);
    padding: 12px 15px;
    background: transparent;
    display: block;
    margin: 0px auto;
}

.login-with li button:hover {
    color: var(--white);
    background: var(--mainColor);
}

.main-content-private {
    width: calc(100% - 290px); /* 100% - (width of #sidebar-container) */
}

.card-header h6 {
    color: var(--white);
}

.card-header.cancel {
    background-color: #c04040;
}

.card-header.cancel h6 {
    color: var(--white);
}

.z-index-10 {
    z-index: 10 !important;
}

.image-gallery-description {
    background: none !important;
    color: #495057 !important;
    position: unset !important;
    font-family: inherit;
    display: block;
    margin-top: 5px;
}

label {
    cursor: default;
}

.pointer {
    cursor: pointer !important;
}

.reset-password-container {
    width: fit-content;
    margin: auto;
}

.resetpassword-form input {
    width: 350px;
    max-width: 100%;
    height: 40px !important;
}

.diag-card {
    width: 500px;
    max-width: 100%;
    color: #1068CE;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

.diag-info {
    margin-bottom: 20px;
}

.diag-title {
    text-align: center;
    width: 100%;
    padding: 10px;
    background: linear-gradient(to right, var(--mainColorLight), var(--mainColorDark));
    color: var(--white);
    margin-bottom: 0px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.diag-body {
    padding: 15px;
    color: var(--bodyText);
}

.diag-body > div {
    display: flex;
    justify-content: space-between;
}

.diag-success {
    color: #19991d;
}
.diag-error {
    color: red;
}

.advanced-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.advanced-container #advanced {
    margin-right: auto;
}

body .login .card-body {
    min-height: 195px;
}

.btn-login {
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.btn-login i {
    font-size: 20px;
}

.login-form .login-buttons {
    display: flex;
    justify-content: space-between;
}

.call-in-section {
    position: relative;
}

.login p {
    font-size: 1rem;
}

.btn-sipass {
    background-color: #ffffff;
    color: #000;
    height: 48px;
    font-size: 18px;
    border-radius: 5px;
    font-family: Republika Bold, Arial;
    font-weight: 600;
    display: flex;
    align-items: center;
    transition: all 100ms ease-in-out;
    border: 1px solid #000;
    padding: 5px 12px;
}

.btn-sipass:hover {
    background-color: #fc6621;
    color: #ffffff;
}

.btn-sipass:active,
.btn-sipass:focus {
    background-color: #fc7d42;
}

.icon-sipass {
    display: block;
    width: 30px;
    height: 30px;
    background-image: url('../../img/icons/sipass-logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
}

.btn-sipass:hover .icon-sipass,
.btn-sipass:active .icon-sipass,
.btn-sipass:focus .icon-sipass {
    background-image: url('../../img/icons/sipass-logo-white.svg');
}

.login .container-login .container-sipass h5,
.login .container-login .container-normal h5,
.login .container .container-register h5 {
    font-size: 1.8rem !important;
    margin-bottom: 30px;
}

.login .container {
    max-width: 1020px;
    box-shadow: 5px 0.75rem 1.5rem rgb(18 38 63 / 15%);
}

.login .container-login {
    padding: 30px;
    background-color: var(--elementBG);
}

.login .container-register {
    padding: 30px;
    background-color: var(--mainBGColor);
    background: var(--mainBGColor);
    color: var(--white);
}

.login .container-sipass {
    color: var(--bodyText);
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.login .container-normal {
    color: var(--bodyText);
    padding-top: 30px;
}

.login .container-normal h5,
.login .container-sipass h5 {
    color: var(--bodyText);
    font-weight: 600;
}

.login .container-register h5 {
    color: var(--white);
    font-weight: 600;
}

.login-img {
    max-width: 100%;
    margin: auto;
    background-color: var(--mainBGColor);
    background-image: url(../../img/random/login.png);
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.login-right,
.login-left {
    align-self: stretch;
}

.login-right > div,
.login-left > div {
    height: 100%;
}

.current {
    text-align: center;
}

#hamburger-overlay,
#leftSide-overlay {
    background-color: #000;
    opacity: 0.2;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 105;
}

#hamburger-menu {
    position: relative;
    cursor: pointer;
    width: 25px;
    height: 20px;
    align-self: center;
}

#hamburger-menu span {
    position: absolute;
    display: inline-block;
    height: 3px;
    width: 100%;
    transition: all ease 0.3s;
    background-color: var(--white);
    left: 0;
}

#hamburger-menu span:first-child {
    top: 0;
}

#hamburger-menu span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
}

#hamburger-menu span:nth-child(3) {
    bottom: 0;
}

#hamburger-menu.active span:first-child {
    transform: rotate(45deg) translate(3px, 9px);
}

#hamburger-menu.active span:nth-child(2) {
    opacity: 0;
}

#hamburger-menu.active span:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -9px);
}

#leftside-menu,
#hamburger-menu {
    z-index: 250;
}

#mobile-nav {
    z-index: 200
}

#leftside-menu {
    position: fixed;
    left: 5px;
    top: 15px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.3s;
}

#leftside-menu span {
    width: 15px;
}

#leftside-menu.active span {
    width: 25px
}

#leftside-menu span:first-child {
    transform: translateY(2px) rotate(40deg) ;
}

#leftside-menu span:nth-child(2) {
    transform:  translateY(-2px) rotate(-40deg);
}

#leftside-menu.active span:first-child {
    transform: translateY(6px) rotate(40deg) ;
}
#leftside-menu.active span:nth-child(2) {
    opacity: 1;
    transform: translateY(-6px) rotate(-40deg) ;
}

#footer .footer-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: fit-content;
    margin-bottom: 30px;
}

#footer .footer-content .title {
    color: var(--footerTextColor);
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
}

.footer-content .links-list li a {
    color: var(--footerTextColor);
    font-weight: 400;
}

#footer .links-list button {
    padding: 10px 0 8px;
    color: var(--footerTextColor);
    font-weight: 400;
    background-color: transparent;
    border: none;
    cursor: default;
}

#footer .links-list .button-as-link {
    cursor: pointer;
}

#footer .links-list .button-as-link:hover,
.footer-content .links-list li a:hover  {
    color: var(--hover);
}

.footer-widget .links-list {
    height: 100%;
    max-height: 200px;
    overflow-y: auto;
}

/* width */
.footer-widget .links-list::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.footer-widget .links-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 4px;
}

/* Handle */
.footer-widget .links-list::-webkit-scrollbar-thumb {
    background: var(--mainColorLight);
    border-radius: 4px;
}

#footer .widget-links {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#footer.footer-no-img {
    background-image: none;
    background-color: var(--footerBgColor);
    padding: 30px 0 0px 0;
}

#footer.footer-no-img p {
    color: var(--footerTextColor);
}

.gdpr-link {
    color: var(--mainColor);
    text-decoration: underline;
}

.filters-saved,
.filters-selected {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    margin: 15px 0;
}

.filters-saved h5,
.filters-selected h5 {
    font-size: 1.3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters-saved h5 {
    line-height: 33.5px !important;
}

.filters-saved li,
.filters-selected li {
    padding: 0px;
    display: flex;
    align-items: center;
}

.filters-saved-item {
    color: var(--mainColor);
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
}

.filters-saved-item:hover {
    color: var(--hover);
}

.filters-saved i,
.filters-selected i {
    font-size: 1.2rem;
    color: var(--error);
    margin-right: 5px;
    cursor: pointer;
}

.filter-reset {
    border: 1px solid var(--error);
    color: var(--error);
    font-size: 1rem;
    font-weight: 600;
}

.filter-reset:hover {
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
}

.filter-item {
    color: var(--mainColor);
    font-weight: 400;
    font-size: 1rem;
}

.page-link {
    background-color: var(--elementBG);
    cursor: pointer;
}

.page-link:hover {
    background-color: var(--lightBG);
    border-color: var(--lightBG);
}

.card-header {
    background-color: var(--secondaryBGColor);
}

.cut-banner {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 140px;
    background-color: var(--bodyBG);
    z-index: 30;
}

.nav-tabs .nav-link.active {
    background: var(--elementBG);
}

.page-item.disabled .page-link {
    background-color: var(--elementBG);
    color: var(--textColor);
}

body .ant-picker,
.ant-picker-input > input,
.ant-picker-suffix {
    color: var(--textColor) !important;
}

.ant-picker,
.css-yk16xz-control,
.css-1pahdxg-control {
    background-color: var(--elementBG) !important;
}

.register-gdpr {
    height: 220px;
    overflow-y: scroll;
    margin: 25px 0px;
    padding: 10px;
    border: 1px solid rgba(97, 90, 191, 0.2);
}

/* width */
.register-gdpr::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.register-gdpr::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(200, 200, 200);
    border-radius: 10px;
}

/* Handle */
.register-gdpr::-webkit-scrollbar-thumb {
    background: var(--mainColor);
    border-radius: 10px;
}

/* Handle on hover */
.register-gdpr::-webkit-scrollbar-thumb:hover {
    background: var(--mainColorDark);
}

/* Scrollbar for Firefox */
.register-gdpr {
    scrollbar-color:  var(--mainColor) rgb(200, 200, 200);
    scrollbar-width: thin;
}

.mandatory-files a {
    color: var(--mainColor);
    font-weight: 600;
}

.navbar-brand {
    display: inline-block;
    padding: 0 0 0 45px;
    margin-left: -4px;
    white-space: nowrap;
    color: var(--white);
    font-size: 2rem;
}

.navbar-brand:hover {
    color: var(--white);
}

.navbar-brand.triglav {
    background-repeat: no-repeat;
    background-position: left;
    background-size: 40px;
    line-height: 100%;
    background-image: url(../../img/random/triglav-w.svg);
}

.displayLabel {
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}

.divDisplay {
    font-size: 1.12rem;
}

.history-container {
    font-size: 0.96rem;
}

.link {
    color: #1b65c2;
    cursor: pointer;
    text-decoration: underline;
}

.blinkRed {
    color: var(--error);
    animation: blinker 1s linear infinite;
}

#preloader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: transparent;
}

.preloader-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.3;
}
.preloader-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50%, -50%;
}

.bid-amount-solo {
    width: 100% !important;
}

.auction-item-2 {
    padding: 10px !important;
    margin: 0px !important;
}

.auction-item-2 .auction-content .bid-area .bid-amount-solo::before {
    width: 0px !important;
}

.auction-item-2 .auction-thumb video {
    width: 100%;
    max-width: 100%;
}

.auction-item-2 .auction-text-image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50px;
    min-height: 38px;
}

.auction-item-2 .auction-description {
    font-size: 12.5px;
    line-height: 19px;
    overflow: hidden;
    width: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.filter-title {
    font-size: 1.4rem;
    font-weight: 500;
}

/* LOGO CAROUSEL */
.slick-track {
    display: flex !important;
    align-items: center !important;
}

.slick-initialized .slick-slide > div > div {
    filter: grayscale(1);
    padding: 20px 50px;
    text-align: center;
}

.slick-initialized .slick-slide > div > div:hover {
    filter: grayscale(0);
}

.slick-initialized .slick-slide div img {
    margin: auto;
    cursor: pointer;
}

.slick-prev:before, .slick-next:before {
    color: var(--mainColor) !important;
    font-family: inherit !important;
    opacity: 1 !important;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 0 !important;
}
.logo-showcase-break {
    flex-basis: 100%;
    height: 0;
}
.logo-showcase-logo-showcase-content {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.logo-showcase-grid-container {
    margin-bottom: -1px;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1100px;
}

.logo-showcase-logo-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    background-color: rgb(238, 242, 255);
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 2px;
    position: relative;
    max-width: 100%;
    width: fit-content;
    cursor: pointer;
}

.logo-showcase-logo-inner {
    filter:grayscale(1);
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.logo-showcase-logo-inner:hover {
    filter:grayscale(0);
}

.logo-showcase-logo-innerInner {
    width: 85%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-showcase-logo-image {
    display: block !important;
    outline: none !important;
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    backface-visibility: hidden;
    transition: .3s ease all;
    max-height: 100%;
    object-fit: contain;
}

.product-tab-menu-area {
    background-color: #fff !important;
}

.product-tab-menu li a.active {
    background: #f9f1ff;
    box-shadow: 0px 0px 20px 5px var(--mainColor);
}

.contact-form .form-group label {
    left: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-content-bottom {
    margin-top: 80px;
}

.section-margin-top {
    margin-top: 6rem !important;
}

.banner-content .custom-title,
.banner-content h5,
.banner-content ul {
    margin-bottom: 15px;
    margin-left: -15px;
}

.banner-content p {
    font-size: 1.2rem;
    line-height: 25px;
    margin: 0 0 33px -15px;
}

section .section-title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 35px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
}

.social-icons {
    justify-content: start !important;
}

.print_button {
    width: auto;
    display: inline-block;
    margin-left: 1.25rem;
    background: none;
    border: none;
}

.print_button span {
    line-height: 16px;
}

.mandatory {
    background: var(--mandatory) !important;
}

/* Media querys */
@media (min-width: 1400px) {
    .container {
        max-width: 1330px;
    }
}

@media (min-width: 1200px) {
    .banner-content .custom-title {
        font-size: 1.8rem;
        line-height: 30px;
    }
}

@media (max-width: 1199px) {
    .filter-title  {
        font-size: 1.2rem !important;
    }

    .banner-content .custom-title {
        font-size: 1.5rem;
        line-height: 25px;
    }

    .banner-content p {
        font-size: 1.1rem !important;
        line-height: 20px !important;
        margin-bottom: 35px !important;
    }

    .logo-showcase-grid-container {
        width: 100%;
    }
}

@media (min-width: 992px) {
    #leftSide-overlay {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .main-content-private {
        width: 100%;
    }

    .md-child-border-bot > div:last-child {
        border-bottom: 1px solid rgb(180, 180, 180);
        margin-bottom: 1.5rem;
    }
}

@media (min-width: 768px) {
    #hamburger-overlay {
        display: none !important;
    }
}

@media (max-width: 767px) {
    #register .verification-input {
        flex-direction: column;
        align-items: flex-start !important;
    }

    #register .verification-input > div {
        margin-bottom: 10px;
    }

    #footer > div {
        margin-top: 30px;
    }

    .button-link {
        color: var(--bodyText);
        width: 100%;
        text-align: left;
    }

    .menu {
        position: absolute;
        top: 0px;
        right: 1px;
        width: auto;
        max-width: calc(100% - 2px);
        /* max-height: calc(100vh - 111px);
        overflow-y: scroll; */
        border-radius: 5px;
        border: 1px solid var(--mainColor);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .menu::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .menu {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .menu .cart-button-area {
        padding: 10px 20px;
    }

    .menu .menu-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .menu .cart-button-area li {
        border: none;
    }

    .menu .cart-button-area li button.header-item  {
        background-color: var(--elementBG);
    }

    .menu .cart-button-area li button.header-item i {
        color: var(--bodyText) !important;
    }

    .menu .cart-button-area li .login-btn {
        background-color: var(--mainBGColor);
        color: var(--headerTextColor);
        width: 100%;
    }

    .header-top-wrapper .cart-button-area {
        display: none;
    }

    .md-mb button {
        margin-bottom: 1rem;
    }

    .filter-title  {
        font-size: 1rem !important;
    }

    .banner-content-bottom {
        margin-top: 80px;
    }
}

@media (max-width: 575px) {
    .footer-bottom-wrapper {
        flex-direction: column;
    }

    #footer {
        background-image: none !important;
        background-color: var(--footerBgColor);
        padding-top: 50px;
    }

    #footer .footer-top {
        padding-top: 0px;
    }

    .login-form .verification-input {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .login-form .verification-input > div {
        margin-bottom: 10px;
    }

    .banner-content-bottom {
        margin-top: 80px;
    }

    .banner-content .custom-title {
        font-size: 1.3rem;
        line-height: 20px;
    }

    .banner-content p {
        font-size: 1rem !important;
        line-height: 18px !important;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media print {
    #printme,
    #printme input,
    #printme textarea{
        font-size: 10px;
    }

    #singlePage .card {
        border: none;
    }

    #phplive_btn_1598277422_clone {
        display: none;
    }

    footer,
    header,
    #breadcrumbs,
    .print_button {
        display: none;
    }

    #leftside-menu,
    .sidebar-container,
    .sidebar-container.active,
    .vertical-menu {
        display: none;
    }

    .dontprint,
    .btn-primary, .btn-danger {
        display: none;
    }
}
