/* Color schemes for FURS */
[class*="blackWhite"] {
    --bodyText: #000 !important;
    --footerTextColor: #000 !important;
    --headerTextColor: #000 !important;
    --hover: #000 !important;
    --secondaryColor: #000 !important;
    --white: #000 !important;
    --error: #000 !important;
    --mainColor: #000 !important;
    --dark: #000 !important;

    --bodyBG: #fff !important;
    --elementBG: #fff !important;
    --footerBgColor: #fff !important;
    --secondaryBGColor: #fff !important;
    --mandatory: #fff !important;
    --mainBGColor: #fff !important;
    --lightBG: #fff !important;
    --darkBG: #fff !important;
    --mainColorLight: #fff !important;
}

[class*="whiteBlack"] {
    --bodyText: #fff !important;
    --footerTextColor: #fff !important;
    --headerTextColor: #fff !important;
    --hover: #fff !important;
    --secondaryColor: #fff !important;
    --white: #fff !important;
    --error: #fff !important;
    --mainColor: #fff !important;
    --dark: #fff !important;

    --bodyBG: #000 !important;
    --elementBG: #000 !important;
    --footerBgColor: #000 !important;
    --secondaryBGColor: #000 !important;
    --mandatory: #000 !important;
    --mainBGColor: #000 !important;
    --lightBG: #000 !important;
    --darkBG: #000 !important;
    --mainColorLight: #000 !important;
}

[class*="blackBeige"] {
    --bodyText: #000 !important;
    --footerTextColor: #000 !important;
    --headerTextColor: #000 !important;
    --hover: #000 !important;
    --secondaryColor: #000 !important;
    --white: #000 !important;
    --error: #000 !important;
    --mainColor: #000 !important;
    --dark: #000 !important;

    --bodyBG: #f5f5dc !important;
    --elementBG: #f5f5dc !important;
    --footerBgColor: #f5f5dc !important;
    --secondaryBGColor: #f5f5dc !important;
    --mandatory: #f5f5dc !important;
    --mainBGColor: #f5f5dc !important;
    --lightBG: #f5f5dc !important;
    --darkBG: #f5f5dc !important;
    --mainColorLight: #f5f5dc !important;
}

[class*="blueWhite"] {
    --bodyText: #00008b !important;
    --footerTextColor: #00008b !important;
    --headerTextColor: #00008b !important;
    --hover: #00008b !important;
    --secondaryColor: #00008b !important;
    --white: #00008b !important;
    --error: #00008b !important;
    --mainColor: #00008b !important;
    --dark: #00008b !important;

    --bodyBG: #fff !important;
    --elementBG: #fff !important;
    --footerBgColor: #fff !important;
    --secondaryBGColor: #fff !important;
    --mandatory: #fff !important;
    --mainBGColor: #fff !important;
    --lightBG: #fff !important;
    --darkBG: #fff !important;
    --mainColorLight: #fff !important;
}

[class*="blackGreen"] {
    --bodyText: #000 !important;
    --footerTextColor: #000 !important;
    --headerTextColor: #000 !important;
    --hover: #000 !important;
    --secondaryColor: #000 !important;
    --white: #000 !important;
    --error: #000 !important;
    --mainColor: #000 !important;
    --dark: #000 !important;

    --bodyBG: #00ff00 !important;
    --elementBG: #00ff00 !important;
    --footerBgColor: #00ff00 !important;
    --secondaryBGColor: #00ff00 !important;
    --mandatory: #00ff00 !important;
    --mainBGColor: #00ff00 !important;
    --lightBG: #00ff00 !important;
    --darkBG: #00ff00 !important;
    --mainColorLight: #00ff00 !important;
}

[class*="blackYellow"] {
    --bodyText: #000 !important;
    --footerTextColor: #000 !important;
    --headerTextColor: #000 !important;
    --hover: #000 !important;
    --secondaryColor: #000 !important;
    --white: #000 !important;
    --error: #000 !important;
    --mainColor: #000 !important;
    --dark: #000 !important;

    --bodyBG: #ffe800 !important;
    --elementBG: #ffe800;
    --footerBgColor: #ffe800 !important;
    --secondaryBGColor: #ffe800 !important;
    --mandatory: #ffe800 !important;
    --mainBGColor: #ffe800 !important;
    --lightBG: #ffe800 !important;
    --darkBG: #ffe800 !important;
    --mainColorLight: #ffe800 !important;
}

[class*="blueYellow"] {
    --bodyText: #00008b !important;
    --footerTextColor: #00008b !important;
    --headerTextColor: #00008b !important;
    --hover: #00008b !important;
    --secondaryColor: #00008b !important;
    --white: #00008b !important;
    --error: #00008b !important;
    --mainColor: #00008b !important;
    --dark: #00008b !important;

    --bodyBG: #ffe800 !important;
    --elementBG: #ffe800 !important;
    --footerBgColor: #ffe800 !important;
    --secondaryBGColor: #ffe800 !important;
    --mandatory: #ffe800 !important;
    --mainBGColor: #ffe800 !important;
    --lightBG: #ffe800 !important;
    --darkBG: #ffe800 !important;
    --mainColorLight: #ffe800 !important;
}

[class*="yellowBlue"] {
    --bodyText: #ffe800 !important;
    --footerTextColor: #ffe800 !important;
    --headerTextColor: #ffe800 !important;
    --hover: #ffe800 !important;
    --secondaryColor: #ffe800 !important;
    --white: #ffe800 !important;
    --error: #ffe800 !important;
    --mainColor: #ffe800 !important;
    --dark: #ffe800 !important;

    --bodyBG: #00008b !important;
    --elementBG: #00008b !important;
    --footerBgColor: #00008b !important;
    --secondaryBGColor: #00008b !important;
    --mandatory: #00008b !important;
    --mainBGColor: #00008b !important;
    --lightBG: #00008b !important;
    --darkBG: #00008b !important;
    --mainColorLight: #00008b !important;
}

[class*="turquoiseBlack"] {
    --bodyText: #00f0ff !important;
    --footerTextColor: #00f0ff !important;
    --headerTextColor: #00f0ff !important;
    --hover: #00f0ff !important;
    --secondaryColor: #00f0ff !important;
    --white: #00f0ff !important;
    --error: #00f0ff !important;
    --mainColor: #00f0ff !important;
    --dark: #00f0ff !important;

    --bodyBG: #000 !important;
    --elementBG: #000 !important;
    --footerBgColor: #000 !important;
    --secondaryBGColor: #000 !important;
    --mandatory: #000 !important;
    --mainBGColor: #000 !important;
    --lightBG: #000 !important;
    --darkBG: #000 !important;
    --mainColorLight: #000 !important;
}

[class*="blackPurple"] {
    --bodyText: #000 !important;
    --footerTextColor: #000 !important;
    --headerTextColor: #000 !important;
    --hover: #000 !important;
    --secondaryColor: #000 !important;
    --white: #000 !important;
    --error: #000 !important;
    --mainColor: #000 !important;
    --dark: #000 !important;

    --bodyBG: #d600d6 !important;
    --elementBG: #d600d6 !important;
    --footerBgColor: #d600d6 !important;
    --secondaryBGColor: #d600d6 !important;
    --mandatory: #d600d6 !important;
    --mainBGColor: #d600d6 !important;
    --lightBG: #d600d6 !important;
    --darkBG: #d600d6 !important;
    --mainColorLight: #d600d6 !important;
}

body.disabled-blackWhite .banner-section,
body.disabled-whiteBlack .banner-section,
body.disabled-blackBeige .banner-section,
body.disabled-blueWhite .banner-section,
body.disabled-blackGreen .banner-section,
body.disabled-blackYellow .banner-section,
body.disabled-blueYellow .banner-section,
body.disabled-yellowBlue .banner-section,
body.disabled-turquoiseBlack .banner-section,
body.disabled-blackPurple .banner-section,
body.disabled-blackWhite #footer,
body.disabled-whiteBlack #footer,
body.disabled-blackBeige #footer,
body.disabled-blueWhite #footer,
body.disabled-blackGreen #footer,
body.disabled-blackYellow #footer,
body.disabled-blueYellow #footer,
body.disabled-yellowBlue #footer,
body.disabled-turquoiseBlack #footer,
body.disabled-blackPurple #footer {
    background-image: none !important;
}

body.disabled-blackWhite .custom-select,
body.disabled-whiteBlack .custom-select,
body.disabled-blackBeige .custom-select,
body.disabled-blueWhite .custom-select,
body.disabled-blackGreen .custom-select,
body.disabled-blackYellow .custom-select,
body.disabled-blueYellow .custom-select,
body.disabled-yellowBlue .custom-select,
body.disabled-turquoiseBlack .custom-select,
body.disabled-blackPurple .custom-select {
    background: var(--elementBG) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}

body.disabled-blackWhite .form-control,
body.disabled-whiteBlack .form-control,
body.disabled-blackBeige .form-control,
body.disabled-blueWhite .form-control,
body.disabled-blackGreen .form-control,
body.disabled-blackYellow .form-control,
body.disabled-blueYellow .form-control,
body.disabled-yellowBlue .form-control,
body.disabled-turquoiseBlack .form-control,
body.disabled-blackPurple .form-control {
    background-color: var(--elementBG)
}

body.disabled-blackWhite .swal2-popup,
body.disabled-whiteBlack .swal2-popup,
body.disabled-blackBeige .swal2-popup,
body.disabled-blueWhite .swal2-popup,
body.disabled-blackGreen .swal2-popup,
body.disabled-blackYellow .swal2-popup,
body.disabled-blueYellow .swal2-popup,
body.disabled-yellowBlue .swal2-popup,
body.disabled-turquoiseBlack .swal2-popup,
body.disabled-blackPurple .swal2-popup {
    background-color: var(--elementBG)
}

/* body.disabled-blackWhite .login .container-register,
body.disabled-whiteBlack .login .container-register,
body.disabled-blackBeige .login .container-register,
body.disabled-blueWhite .login .container-register,
body.disabled-blackGreen .login .container-register,
body.disabled-blackYellow .login .container-register,
body.disabled-blueYellow .login .container-register,
body.disabled-yellowBlue .login .container-register,
body.disabled-turquoiseBlack .login .container-register,
body.disabled-blackPurple .login .container-register {
    background-color: var(--elementBG);
    color: var(--bodyText);
} */

body.disabled-blackWhite .account-wrapper .right-side,
body.disabled-whiteBlack .account-wrapper .right-side,
body.disabled-blackBeige .account-wrapper .right-side,
body.disabled-blueWhite .account-wrapper .right-side,
body.disabled-blackGreen .account-wrapper .right-side,
body.disabled-blackYellow .account-wrapper .right-side,
body.disabled-blueYellow .account-wrapper .right-side,
body.disabled-yellowBlue .account-wrapper .right-side,
body.disabled-turquoiseBlack .account-wrapper .right-side,
body.disabled-blackPurple .account-wrapper .right-side {
    background: -webkit-linear-gradient(90deg, var(--elementBG) 0%, var(--elementBG) 100%) !important;
}

body.disabled-blackWhite .btn:hover,
body.disabled-whiteBlack .btn:hover,
body.disabled-blackBeige .btn:hover,
body.disabled-blueWhite .btn:hover,
body.disabled-blackGreen .btn:hover,
body.disabled-blackYellow .btn:hover,
body.disabled-blueYellow .btn:hover,
body.disabled-yellowBlue .btn:hover,
body.disabled-turquoiseBlack .btn:hover,
body.disabled-blackPurple .btn:hover,
body.disabled-blackWhite .btn-danger,
body.disabled-whiteBlack .btn-danger,
body.disabled-blackBeige .btn-danger,
body.disabled-blueWhite .btn-danger,
body.disabled-blackGreen .btn-danger,
body.disabled-blackYellow .btn-danger,
body.disabled-blueYellow .btn-danger,
body.disabled-yellowBlue .btn-danger,
body.disabled-turquoiseBlack .btn-danger,
body.disabled-blackPurple .btn-danger,
body.disabled-blackWhite .btn-success,
body.disabled-whiteBlack .btn-success,
body.disabled-blackBeige .btn-success,
body.disabled-blueWhite .btn-success,
body.disabled-blackGreen .btn-success,
body.disabled-blackYellow .btn-success,
body.disabled-blueYellow .btn-success,
body.disabled-yellowBlue .btn-success,
body.disabled-turquoiseBlack .btn-success,
body.disabled-blackPurple .btn-success
{
    background-color: var(--elementBG);
    border-color: var(--textColor);
    color: var(--textColor);
}

body.disabled-blackWhite .main-banner,
body.disabled-whiteBlack .main-banner,
body.disabled-blackBeige .main-banner,
body.disabled-blueWhite .main-banner,
body.disabled-blackGreen .main-banner,
body.disabled-blackYellow .main-banner,
body.disabled-blueYellow .main-banner,
body.disabled-yellowBlue .main-banner,
body.disabled-turquoiseBlack .main-banner,
body.disabled-blackPurple .main-banner {
    background-color: var(--elementBG) !important;
}

body.disabled-blackWhite .main-banner img,
body.disabled-whiteBlack .main-banner img,
body.disabled-blackBeige .main-banner img,
body.disabled-blueWhite .main-banner img,
body.disabled-blackGreen .main-banner img,
body.disabled-blackYellow .main-banner img,
body.disabled-blueYellow .main-banner img,
body.disabled-yellowBlue .main-banner img,
body.disabled-turquoiseBlack .main-banner img,
body.disabled-blackPurple .main-banner  img {
    display: none !important;
}


body.disabled-blackWhite .header-top,
body.disabled-whiteBlack .header-top,
body.disabled-blackBeige .header-top,
body.disabled-blueWhite .header-top,
body.disabled-blackGreen .header-top,
body.disabled-blackYellow .header-top,
body.disabled-blueYellow .header-top,
body.disabled-yellowBlue .header-top,
body.disabled-turquoiseBlack .header-top,
body.disabled-blackPurple .header-top {
    background-color: var(--elementBG) !important;
}



body.disabled-blackWhite .btn-sipass,
body.disabled-whiteBlack .btn-sipass,
body.disabled-blackBeige .btn-sipass,
body.disabled-blueWhite .btn-sipass,
body.disabled-blackGreen .btn-sipass,
body.disabled-blackYellow .btn-sipass,
body.disabled-blueYellow .btn-sipass,
body.disabled-yellowBlue .btn-sipass,
body.disabled-turquoiseBlack .btn-sipass,
body.disabled-blackPurple .btn-sipass {
    background-color: var(--elementBG) !important;
    color: var(--bodyText) !important;
}

body.disabled-blackWhite .btn-sipass:hover .icon-sipass,
body.disabled-blackWhite .btn-sipass:active .icon-sipass,
body.disabled-blackWhite .btn-sipass:focus .icon-sipass,
body.disabled-whiteBlack .btn-sipass:hover .icon-sipass,
body.disabled-whiteBlack .btn-sipass:active .icon-sipass,
body.disabled-whiteBlack .btn-sipass:focus .icon-sipass,
body.disabled-blackBeige .btn-sipass:hover .icon-sipass,
body.disabled-blackBeige .btn-sipass:active .icon-sipass,
body.disabled-blackBeige .btn-sipass:focus .icon-sipass,
body.disabled-blueWhite .btn-sipass:hover .icon-sipass,
body.disabled-blueWhite .btn-sipass:active .icon-sipass,
body.disabled-blueWhite .btn-sipass:focus .icon-sipass,

body.disabled-blackGreen .btn-sipass:hover .icon-sipass,
body.disabled-blackGreen .btn-sipass:active .icon-sipass,
body.disabled-blackGreen .btn-sipass:focus .icon-sipass,


body.disabled-blackYellow .btn-sipass:hover .icon-sipass,
body.disabled-blackYellow .btn-sipass:active .icon-sipass,
body.disabled-blackYellow .btn-sipass:focus .icon-sipass,

body.disabled-blueYellow .btn-sipass:hover .icon-sipass,
body.disabled-blueYellow .btn-sipass:active .icon-sipass,
body.disabled-blueYellow .btn-sipass:focus .icon-sipass,

body.disabled-yellowBlue .btn-sipass:hover .icon-sipass,
body.disabled-yellowBlue .btn-sipass:active .icon-sipass,
body.disabled-yellowBlue .btn-sipass:focus .icon-sipass,

body.disabled-turquoiseBlack .btn-sipass:hover .icon-sipass,
body.disabled-turquoiseBlack .btn-sipass:active .icon-sipass,
body.disabled-turquoiseBlack .btn-sipass:focus .icon-sipass,

body.disabled-blackPurple .btn-sipass:hover .icon-sipass,
body.disabled-blackPurple .btn-sipass:active .icon-sipass,
body.disabled-blackPurple .btn-sipass:focus .icon-sipass
{
    background-image: url('../../img/icons/sipass-logo.svg');
}