div.dt-buttons {
    float: none !important;
    margin-bottom: 40px;
}
.dt-buttons .dt-button {
    background: none !important;
    border: none !important;
    font-size: 14px;
    color: var(--dark);
}
.dt-buttons .dt-button:hover {
    background: none;
    border: none;
}
